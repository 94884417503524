<template>
  <div>
    <loader v-if="loading"></loader>
    <table-component
      v-else
      :items="items"
      :edit="true"
      :delet="true"
      :pages="pages"
      :search="true"
      :search-items="categoryItems"
      @get-search="getModal"
      @change_page="changePage"
      @delete-button="deleteButton"
      @seo-save="dialog = true; seo.category = $event"
      @search="search"
      @image_click="setImage($event)"
      @sort-items="sortBody($event)"
    >
      <template v-slot:default>
        <div class="action-row">
          <v-row class="align-center justify-end" no-gutters>
            <!-- Фильтры перемещены вправо -->
            <v-col cols="auto">
              <v-menu offset-y :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small color="primary" v-bind="attrs" v-on="on">
                    Фильтры
                    <v-icon right>mdi-filter</v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-card-text>
                    <v-row dense>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          v-model="filters.deleted"
                          label="Удаленные"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          v-model="filters.hidden"
                          label="Скрытые"
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          v-model="filters.special"
                          label="Спец."
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="auto">
                        <v-checkbox
                          dense
                          v-model="filters.promo"
                          label="Промо"
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn small color="primary" @click="applyFilters">
                      Применить
                    </v-btn>
                    <v-btn small text @click="resetFilters">
                      Сбросить
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <!-- Кнопка для скачивания древа -->
            <v-col cols="auto">
              <v-btn small color="warning" @click="downloadTree">
                Скачать древо
                <v-icon right>mdi-tray-arrow-up</v-icon>
              </v-btn>
            </v-col>
            <!-- Кнопка для очередности каталога -->
            <v-col cols="auto">
              <v-btn small color="#5d4bd0" dark to="/catalog">
                Очередность каталога
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </template>
    </table-component>

    <category-dialog
      :show="dialogCategory"
      :loading="dialogLoading"
      :category="category"
      @close="dialogCategory = false"
    ></category-dialog>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import TableComponent from "@/components/TableComponent";
import category from "@/model/category.js";
import { mapGetters, mapActions } from "vuex";
import CategoryDialog from "@/components/CategoryDialog";
import showMessage from "@/Functions/message";
import downloadFile from "@/Functions/downloadFile";
import api from "@/api";

export default {
  components: {
    CategoryDialog,
    TableComponent,
    Loader,
  },
  data() {
    return {
      loading: true,
      items: {
        head: [
          { id: 1, name: "ID" },
          { id: 2, name: "Имя" },
          { id: 3, name: "Скрыт" },
          { id: 4, name: "Описание" },
          { id: 5, name: "Родитель" },
          { id: 6, name: "Удален" },
          { id: 7, name: "Позиция на странице" },
          { id: 8, name: "Промо" },
          { id: 9, name: "Дочерние категории" },
          { id: 10, name: "Изображение" },
        ],
        body: [],
      },
      pageInput: {
        page: 0,
        pageSize: 10,
      },
      pages: {
        count: 0,
        current: 0,
        elements: 0,
      },
      dialog: false,
      dialogLoading: false,
      dialogCategory: false,
      seo: {
        seo: 0,
        category: 0,
      },
      categoryItems: [],
      category: {},
      filters: {
        deleted: false,
        hidden: false,
        special: false,
        promo: false,
      },
      filterActive: false,
    };
  },
  computed: {
    ...mapGetters({
      CATEGORY: "Category/STATE",
    }),
  },
  async created() {
    this.loading = true;
    this.checkPower();
    await this.setBody();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      GET_PAGINATED_CATEGORY: "Category/GET_PAGINATED_CATEGORY",
      DELETE_CATEGORY: "Category/DELETE_CATEGORY",
      SEARCH_CATEGORY: "Category/SEARCH_CATEGORY",
      GET_CATEGORY: "Category/GET_CATEGORY",
      EDIT_IMAGE: "Category/EDIT_IMAGE",
    }),
    async search(value) {
      this.categoryItems = await this.SEARCH_CATEGORY(value);
    },
    async getModal(value) {
      this.dialogCategory = true;
      this.dialogLoading = true;
      this.category = await this.GET_CATEGORY(value);
      this.dialogLoading = false;
    },
    redirect(value) {
      this.$router.push(`${this.$route.path}/${value}`);
    },
    checkPower() {
      const state = this.$power.check(this.$route.meta.power);
      if (!state) {
        this.$router.push("/");
      }
    },
    async changePage(value) {
      this.pageInput.page = value;
      await this.setBody();
    },
    async setBody() {
      this.loading = true;
      const modelInstance = new category();
      if (this.filterActive) {
        const requestBody = {};
        Object.keys(this.filters).forEach((key) => {
          if (this.filters[key] === true) {
            requestBody[key] = true;
          }
        });
        try {
          const response = await api.Category.filteredCategoryList(requestBody);
          const data = Array.isArray(response.data)
            ? response.data
            : Array.isArray(response)
              ? response
              : [];
          this.items.body = modelInstance.setBody(data);
          let pagesData = modelInstance.setPages(data);
          this.pages = pagesData;
        } catch (error) {
          showMessage("Произошла ошибка или данные еще не добавлены. Попробуйте позже.");
          console.error("Filtered list error:", error);
        }
      } else {
        await this.GET_PAGINATED_CATEGORY(this.pageInput);
        this.items.body = modelInstance.setBody(this.CATEGORY.paginatedCategory);
        let pagesData = modelInstance.setPages(this.CATEGORY.paginatedCategory);
        if (!pagesData || pagesData.length < 1) {
          pagesData.length = 1;
        }
        this.pages = pagesData;
      }
      this.loading = false;
    },
    async deleteButton(item) {
      this.loading = true;
      const errors = [];
      for (let i = 0; i < item.length; i++) {
        const response = await this.DELETE_CATEGORY(item[i].id);
        if (response.type === "error") {
          errors.push(response.data.message);
        }
      }
      if (errors.length) {
        showMessage(errors);
      }
      await this.setBody();
      this.loading = false;
    },
    setImage({ value, id }) {
      if (value.target.files[0].size > 1000000) {
        showMessage("Размер файла превышает 1мб");
      } else {
        const formData = new FormData();
        formData.append("file", value.target.files[0]);
        this.EDIT_IMAGE({
          categoryId: id,
          input: formData,
        }).then((res) => {
          if (res.type === "error") {
            showMessage(res.data.message);
          } else {
            showMessage("Изображение успешно загрузилось, обновление страницы...", true);
            this.setBody();
          }
        });
      }
    },
    sortBody({ currentSort, sortDirection }) {
      this.items.body.sort((a, b) => {
        const modifier = sortDirection === "desc" ? -1 : 1;
        if (a.fields[currentSort - 1].name < b.fields[currentSort - 1].name) {
          return -1 * modifier;
        }
        if (a.fields[currentSort - 1].name > b.fields[currentSort - 1].name) {
          return 1 * modifier;
        }
        return 0;
      });
    },
    async downloadTree() {
      const date = new Date().toLocaleDateString().replaceAll(".", "-");
      this.loading = true;
      try {
        const file = await api.Category.downloadCategory();
        downloadFile(file.data, `Древо категорий от ${date}`);
      } catch (error) {
        showMessage("Ошибка при скачивании древа категорий");
        console.error("Download tree error:", error);
      }
      this.loading = false;
    },
    async applyFilters() {
      this.filterActive = true;
      this.pageInput.page = 0;
      await this.setBody();
    },
    async resetFilters() {
      this.filters = {
        deleted: false,
        hidden: false,
        special: false,
        promo: false,
      };
      this.filterActive = false;
      this.pageInput.page = 0;
      await this.setBody();
    },
  },
};
</script>

<style lang="scss" scoped>
.action-row {
  margin-bottom: 10px;
}
</style>
